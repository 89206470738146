import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Badge from "@mui/material/Badge";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import React from "react";
import { useHistory } from "react-router-dom";
import { styled } from "@mui/material/styles";

export default function PacienteMenu({ backpage = "pacientes" }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const history = useHistory();

  const handleHistoriaClinica = () => {
    history.push({
      pathname: "/pacientehclinica",
      backpage: backpage,
    });
  };
  const handlePrecios = () => {
    history.push({
      pathname: "/pacientelprecios",
    });
  };
  const handlePresupuestos = () => {
    history.push({
      pathname: "/pacientepresupuestos",
    });
  };
  const handleOrdenes = () => {
    history.push({
      pathname: "/pacienteordenes",
    });
  };
  const handleAnamnesis = () => {
    history.push({
      pathname: "/pacienteanamnesis",
    });
  };

  const handleFichados = () => {
    history.push({
      pathname: "/pacientefichados",
    });
  };

  const handleCtaCte = () => {
    history.push({
      pathname: "/pacientectacte",
    });
  };
  function imagenesPaciente() {
    history.push({
      pathname: "/imagenespaciente",
    });
  }
  const handlePlanTratamiento = () => {
    history.push({
      pathname: "/pacienteplantratamiento",
    });
  };
  const handleDiscapacidad = () => {
    history.push({
      pathname: "/pacientediscapacidad",
    });
  };

  const handleQuejas = () => {
    history.push({
      pathname: "/pacientequejas",
    });
  };
  const handleEncuestas = () => {
    history.push({
      pathname: "/pacienteencuestas",
    });
  };
  function handleRecetas() {
    history.push({
      pathname: "/pacienterecetas",
    });
  }

  const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      right: 4,
      top: 25,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: "0 4px",
    },
  }));

  return (
    <>
      <React.Fragment>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            textAlign: "center",
            justifyContent: "center",
            ml: 2,
            mr: 2,
            mt: 2,
            mb: 3,
            // boxShadow: 1,
            width: "100%",
            // backgroundColor: "bgcards",
          }}
        >
          <Tooltip title="Historia Clínica">
            <IconButton
              onClick={handleHistoriaClinica}
              size="small"
              aria-controls={open ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            >
              <Avatar
                variant="rounded"
                sx={{
                  border: "2px solid ",
                  bgcolor: "white",
                  color: "primary.main",
                  width: 36,
                  height: 36,
                  mr: -0.5,
                }}
              >
                HC
              </Avatar>
            </IconButton>
          </Tooltip>
          <Tooltip title="Cuenta Corriente">
            <IconButton
              onClick={handleCtaCte}
              size="small"
              aria-controls={open ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            >
              <Avatar
                variant="rounded"
                sx={{
                  border: "2px solid ",
                  bgcolor: "white",
                  color: "primary.main",
                  width: 36,
                  height: 36,
                  mr: -0.5,
                }}
              >
                CC
              </Avatar>
            </IconButton>
          </Tooltip>
          <Tooltip title="Lista de Precios">
            <IconButton
              onClick={handlePrecios}
              size="small"
              aria-controls={open ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            >
              <Avatar
                variant="rounded"
                sx={{
                  border: "2px solid ",
                  bgcolor: "white",
                  color: "primary.main",
                  width: 36,
                  height: 36,
                  mr: -0.5,
                }}
              >
                LP
              </Avatar>
            </IconButton>
          </Tooltip>
          <Tooltip title="Fichados">
            <IconButton
              onClick={handleFichados}
              size="small"
              aria-controls={open ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            >
              <Avatar
                variant="rounded"
                sx={{
                  border: "2px solid ",
                  bgcolor: "white",
                  color: "primary.main",
                  width: 36,
                  height: 36,
                  mr: -0.5,
                }}
              >
                FI
              </Avatar>
            </IconButton>
          </Tooltip>
          <Tooltip title="Presupuestos">
            <IconButton
              onClick={handlePresupuestos}
              size="small"
              aria-controls={open ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            >
              <Avatar
                variant="rounded"
                sx={{
                  border: "2px solid ",
                  bgcolor: "white",
                  color: "primary.main",
                  width: 36,
                  height: 36,
                  mr: -0.5,
                }}
              >
                PR
              </Avatar>
            </IconButton>
          </Tooltip>
          <Tooltip title="Ordenes de Trabajo">
            <IconButton
              onClick={handleOrdenes}
              size="small"
              aria-controls={open ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            >
              <Avatar
                variant="rounded"
                sx={{
                  border: "2px solid ",
                  bgcolor: "white",
                  color: "primary.main",
                  width: 36,
                  height: 36,
                  mr: -0.5,
                }}
              >
                OT
              </Avatar>
            </IconButton>
          </Tooltip>
          <Tooltip title="Plan de Tratamiento">
            <IconButton
              onClick={handlePlanTratamiento}
              size="small"
              aria-controls={open ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            >
              <Avatar
                variant="rounded"
                sx={{
                  border: "2px solid ",
                  bgcolor: "white",
                  color: "primary.main",
                  width: 36,
                  height: 36,
                  mr: -0.5,
                }}
              >
                PT
              </Avatar>
            </IconButton>
          </Tooltip>
          <Tooltip title="Anamnesis">
            <IconButton
              onClick={handleAnamnesis}
              size="small"
              aria-controls={open ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            >
              <Avatar
                variant="rounded"
                sx={{
                  border: "2px solid ",
                  bgcolor: "white",
                  color: "primary.main",
                  width: 36,
                  height: 36,
                  mr: -0.5,
                }}
              >
                AN
              </Avatar>
            </IconButton>
          </Tooltip>
          <Tooltip title="Quejas y Reclamos">
            <IconButton
              onClick={handleQuejas}
              size="small"
              aria-controls={open ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            >
              <Avatar
                variant="rounded"
                sx={{
                  border: "2px solid ",
                  bgcolor: "white",
                  color: "primary.main",
                  width: 36,
                  height: 36,
                  mr: -0.5,
                }}
              >
                QJ
              </Avatar>
            </IconButton>
          </Tooltip>
          <Tooltip title="Quejas y Reclamos">
            <IconButton
              onClick={handleEncuestas}
              size="small"
              aria-controls={open ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            >
              <Avatar
                variant="rounded"
                sx={{
                  border: "2px solid ",
                  bgcolor: "white",
                  color: "primary.main",
                  width: 36,
                  height: 36,
                  mr: -0.5,
                }}
              >
                EN
              </Avatar>
            </IconButton>
          </Tooltip>
          <Tooltip title="Radiografías">
            <IconButton
              onClick={imagenesPaciente}
              size="small"
              aria-controls={open ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            >
              <Avatar
                variant="rounded"
                sx={{
                  border: "2px solid ",
                  bgcolor: "white",
                  color: "primary.main",
                  width: 36,
                  height: 36,
                  mr: -0.5,
                }}
              >
                RX
              </Avatar>
            </IconButton>
          </Tooltip>
          <Tooltip title="Discapacidad">
            <IconButton
              onClick={handleDiscapacidad}
              size="small"
              aria-controls={open ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            >
              <Avatar
                variant="rounded"
                sx={{
                  border: "2px solid ",
                  bgcolor: "white",
                  color: "primary.main",
                  width: 36,
                  height: 36,
                  mr: -0.5,
                }}
              >
                DI
              </Avatar>
            </IconButton>
          </Tooltip>
          <Tooltip title="Recetas">
            <IconButton
              onClick={handleRecetas}
              size="small"
              aria-controls={open ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            >
              <Avatar
                variant="rounded"
                sx={{
                  border: "2px solid ",
                  bgcolor: "white",
                  color: "primary.main",
                  width: 36,
                  height: 36,
                  mr: -0.5,
                }}
              >
                RC
              </Avatar>
            </IconButton>
          </Tooltip>
        </Box>
      </React.Fragment>
    </>
  );
}
