import PropTypes from "prop-types";
import React, { useState } from "react";
import crypto from "../Crypto";
import { ModeloPost } from "../ModeloCrud";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import {
  Grid,
  Box,
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
  Typography,
} from "@mui/material";
import * as yup from "yup";
import LoadingButton from "@mui/lab/LoadingButton";
import Draggable from "react-draggable";
import MailIcon from "@mui/icons-material/Mail";
import { CapSentence } from "../utils/Utils";
import SendIcon from "@mui/icons-material/Send";
import ModalInformation from "../modals/ModalInformation";
import InfoIcon from "@mui/icons-material/Info";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, row, ...other } = props;

  return (
    <DialogTitle
      sx={{ m: 0, p: 0, bgcolor: "primary.main", color: "white" }}
      {...other}
    >
      <MailIcon sx={{ color: "white", fontSize: 48, ml: 2, mr: 2 }} />
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 4,
            color: (theme) => theme.palette.grey[900],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const validationSchema = yup.object({
  email: yup
    .string()
    .required("Campo Obligatorio.")
    .email("Ingrese un Email válido."),
});

export default function ModalEnviarReceta({ row, showModal, hideModal }) {
  const [modalMessage, setModalMessage] = useState(null);
  const [myloading, setMyLoading] = React.useState(false);
  const currentPatient = crypto.decryptDataStorage("dataPaciente");
  const [modalInformationOpen, setModalInformationOpen] = useState(false);
  const [modalErrorOpen, setModalErrorOpen] = useState(false);

  const handleClose = () => {
    hideModal();
  };

  const hideInformationModal = () => {
    setModalMessage(null);
    setModalErrorOpen(false);
    setModalInformationOpen(false);
    handleClose();
  };

  const formik = useFormik({
    initialValues: {
      email: row.paciente_email,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      enviarImagen(values);
    },
  });

  async function enviarImagen(values) {
    let params =
      "paciente=" +
      CapSentence(currentPatient.apellido) +
      ", " +
      CapSentence(currentPatient.nombres) +
      "&recetaUrl=" +
      row.receta +
      "&email=" +
      values.email;
    setMyLoading(true);
    let result = await ModeloPost(
      "paciente",
      currentPatient.id,
      "enviar-receta-mail",
      params
    );
    setMyLoading(false);
    console.log("resultado email", result);

    if (result.error === false) {
      setModalMessage(result.listado);
      setModalInformationOpen(true);
    } else {
      setModalMessage(result.mensaje);
      setModalErrorOpen(true);
    }
  }

  return (
    <React.Fragment>
      <Draggable cancel=".content, .actions">
        <BootstrapDialog
          hideBackdrop={true}
          onClose={handleClose}
          open={showModal}
          aria-labelledby="draggable-dialog-title"
          fullWidth={true}
          maxWidth="xs"
          sx={{
            "& .MuiDialog-paper": {
              borderRadius: "20px",
            },
            "& .MuiPaper-root": {
              backgroundColor: "#ffffff", // Adjust transparency here
            },
          }}
        >
          <BootstrapDialogTitle
            style={{ cursor: "move" }}
            id="draggable-dialog-title"
            onClose={handleClose}
            row={row}
          >
            Enviar receta
          </BootstrapDialogTitle>
          <form
            key="enviar-imagen-form"
            autoComplete="off"
            onSubmit={formik.handleSubmit}
          >
            <DialogContent className="content">
              <DialogContentText>
                <Grid container spacing={2} marginTop={0.5} marginBottom={4}>
                  <Grid item xs={12}>
                    <TextField
                      id="email"
                      label="Email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      InputLabelProps={{
                        shrink: formik.values.email?.length > 0 ? true : false,
                      }}
                      helperText={
                        formik.touched.email ? formik.errors.email : ""
                      }
                      error={
                        formik.touched.email && Boolean(formik.errors.email)
                      }
                      margin="dense"
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    {modalInformationOpen && modalMessage !== null && (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          backgroundColor: "#1976d2", // Azul para el fondo
                          color: "#ffffff", // Texto blanco
                          borderRadius: "4px",
                          padding: "12px",
                          gap: "8px", // Espaciado entre el ícono y el texto
                          marginY: "8px", // Margen vertical
                        }}
                      >
                        <InfoIcon sx={{ fontSize: 24, color: "#ffffff" }} />
                        <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                          {modalMessage}
                        </Typography>
                      </Box>
                    )}
                  </Grid>
                </Grid>
              </DialogContentText>
              <ModalInformation
                showModal={modalErrorOpen && modalMessage !== null}
                hideModal={hideInformationModal}
                message={modalMessage}
                tipo="error"
              />
            </DialogContent>
            <DialogActions className="actions">
              <Button
                onClick={handleClose}
                color="primary"
                variant="contained"
                loadingPosition="end"
                sx={{
                  backgroundColor: "#cbd5e1",
                  ":hover": {
                    bgcolor: "#475569", // theme.palette.primary.main
                    color: "white",
                  },
                  textTransform: "capitalize",
                }}
              >
                Cancelar
              </Button>
              <LoadingButton
                type="submit"
                color="primary"
                loading={myloading}
                loadingPosition="start"
                startIcon={<SendIcon />}
                variant="contained"
                sx={{
                  backgroundColor: "primary.main",
                  ":hover": {
                    bgcolor: "#475569", // theme.palette.primary.main
                    color: "white",
                  },
                  textTransform: "capitalize",
                }}
              >
                <span>Enviar</span>
              </LoadingButton>
            </DialogActions>
          </form>
        </BootstrapDialog>
      </Draggable>
    </React.Fragment>
  );
}
