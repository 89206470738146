import Box from "@mui/material/Box";
import Badge from "@mui/material/Badge";
import React from "react";
import { useHistory } from "react-router-dom";
import { styled } from "@mui/material/styles";
import MuiButton from "../MuiButton";

export default function PacienteMenuWide({ backpage = "pacientes" }) {
  const history = useHistory();

  const handleHistoriaClinica = () => {
    history.push({
      pathname: "/pacientehclinica",
      backpage: backpage,
    });
  };
  const handlePrecios = () => {
    history.push({
      pathname: "/pacientelprecios",
    });
  };
  const handlePresupuestos = () => {
    history.push({
      pathname: "/pacientepresupuestos",
    });
  };
  const handleOrdenes = () => {
    history.push({
      pathname: "/pacienteordenes",
    });
  };
  const handleAnamnesis = () => {
    history.push({
      pathname: "/pacienteanamnesis",
    });
  };
  const handleQuejas = () => {
    history.push({
      pathname: "/pacientequejas",
    });
  };
  const handleDiscapacidad = () => {
    history.push({
      pathname: "/pacientediscapacidad",
    });
  };
  const handleEncuestas = () => {
    history.push({
      pathname: "/pacienteencuestas",
    });
  };
  const handleRecetas = () => {
    history.push({
      pathname: "/pacienterecetas",
    });
  };
  const handleFichados = () => {
    history.push({
      pathname: "/pacientefichados",
    });
  };

  const handleCtaCte = () => {
    history.push({
      pathname: "/pacientectacte",
    });
  };

  const handlePlanTratamiento = () => {
    history.push({
      pathname: "/pacienteplantratamiento",
    });
  };

  function imagenesPaciente() {
    history.push({
      pathname: "/imagenespaciente",
    });
  }

  const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      right: 4,
      top: 25,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: "0 4px",
    },
  }));

  return (
    <>
      <React.Fragment>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            textAlign: "center",
            justifyContent: "center",
            width: "100%",
            gap: 0.5,
          }}
        >
          <MuiButton
            onClick={handleHistoriaClinica}
            text="H.Clínica"
            width="8em"
            backColor="buttonsubmenu"
            textColor="white"
          />
          <MuiButton
            onClick={handleCtaCte}
            text="Cta. Cte."
            width="8em"
            backColor="buttonsubmenu"
            textColor="white"
          />
          <MuiButton
            onClick={handlePrecios}
            text="L. Precios"
            width="8em"
            backColor="buttonsubmenu"
            textColor="white"
          />
          <MuiButton
            onClick={handleFichados}
            text="Fichados"
            width="8em"
            backColor="buttonsubmenu"
            textColor="white"
          />
          <MuiButton
            onClick={handlePresupuestos}
            text="Presupuestos"
            width="8em"
            backColor="buttonsubmenu"
            textColor="white"
          />
          <MuiButton
            onClick={handleOrdenes}
            text="O.Trabajo"
            width="8em"
            backColor="buttonsubmenu"
            textColor="white"
          />
          <MuiButton
            onClick={handlePlanTratamiento}
            text="P. Tratamiento"
            width="8em"
            backColor="buttonsubmenu"
            textColor="white"
          />
          <MuiButton
            onClick={handleAnamnesis}
            text="Anamnesis"
            width="8em"
            backColor="buttonsubmenu"
            textColor="white"
          />
          <MuiButton
            onClick={handleQuejas}
            text="Quejas"
            width="8em"
            backColor="buttonsubmenu"
            textColor="white"
          />
          <MuiButton
            onClick={handleEncuestas}
            text="Encuestas"
            width="8em"
            backColor="buttonsubmenu"
            textColor="white"
          />
          <MuiButton
            onClick={imagenesPaciente}
            text="Imágenes"
            width="8em"
            backColor="buttonsubmenu"
            textColor="white"
          />
          <MuiButton
            onClick={handleDiscapacidad}
            text="Discapacidad"
            width="8em"
            backColor="buttonsubmenu"
            textColor="white"
          />
          <MuiButton
            onClick={handleRecetas}
            text="Recetas"
            width="8em"
            backColor="buttonsubmenu"
            textColor="white"
          />
        </Box>
      </React.Fragment>
    </>
  );
}
