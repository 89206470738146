import React, { useRef, useState } from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow, { tableRowClasses } from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { IconButton } from "@mui/material";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import MailIcon from "@mui/icons-material/Mail";
import ModalEnviarReceta from "../modals/ModalEnviarReceta";

const RenderRecetas = ({ alldata }) => {
  const tableRef = useRef();
  const [enviarReceta, setEnviarReceta] = useState(false);
  const [selectedReceta, setSelectedReceta] = useState(null);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#374151",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: { xs: 12, sm: 14 },
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    [`&.${tableRowClasses.root}`]: {
      height: 30,
    },
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const handleView = (url) => {
    window.open(url, "_blank");
  };

  const handleSendEmail = (row) => {
    setSelectedReceta(row);
    setEnviarReceta(true);
  };

  const hideEnviarReceta = () => {
    setEnviarReceta(false);
  };

  function Row(props) {
    const { row } = props;
    return (
      <React.Fragment>
        <StyledTableRow key={row.id}>
          <StyledTableCell component="th" scope="row">
            {row.fecha}
          </StyledTableCell>
          <StyledTableCell component="th" scope="row">
            {row.odontologo}
          </StyledTableCell>
          <StyledTableCell>{row.paciente_email}</StyledTableCell>
          <StyledTableCell>{row.receta}</StyledTableCell>
          <StyledTableCell>
            {/* Botón Ver */}
            <IconButton
              color="primary"
              onClick={() => handleView(row.receta)}
              title="Ver receta"
            >
              <ZoomInIcon />
            </IconButton>

            {/* Botón Email */}
            <IconButton
              color="primary"
              onClick={() => handleSendEmail(row)}
              title="Enviar por email"
            >
              <MailIcon />
            </IconButton>
          </StyledTableCell>
        </StyledTableRow>
      </React.Fragment>
    );
  }

  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <Container maxWidth="xl">
        <Typography sx={{ fontSize: 22, mt: 2, mb: 3 }}>Recetas</Typography>
        <TableContainer component={Paper}>
          <Table
            ref={tableRef}
            sx={{ minWidth: { xs: 512, sm: 800 } }}
            aria-label="customized table"
            size="small"
          >
            <TableHead>
              <TableRow>
                <StyledTableCell>Fecha</StyledTableCell>
                <StyledTableCell>Odontólogo</StyledTableCell>
                <StyledTableCell>Email Paciente</StyledTableCell>
                <StyledTableCell>Receta</StyledTableCell>
                <StyledTableCell></StyledTableCell>
                <StyledTableCell></StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {alldata !== null &&
                alldata.length > 0 &&
                alldata
                  .sort((a, b) => (a.orden > b.orden ? 1 : -1))
                  .map((row) => <Row key={row.id} row={row} />)}
              {alldata !== null && alldata.length === 0 && (
                <Box sx={{ ml: 2, mt: 2, mb: 2 }}>
                  <Typography variant="body1" gutterBottom>
                    Sin Recetas.
                  </Typography>
                </Box>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
      {selectedReceta !== null && enviarReceta && (
        <ModalEnviarReceta
          row={selectedReceta}
          showModal={enviarReceta}
          hideModal={hideEnviarReceta}
        />
      )}
    </Box>
  );
};

export default RenderRecetas;
